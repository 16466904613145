import React from "react"
import { connect } from "react-redux"
import { useSelector } from "react-redux"
import { navigate } from "gatsby"
import { FaEdit } from "react-icons/fa"

const ReclamosIndex = ({ location, props }) => {
  const DatosMiSuper = useSelector(state => state.Datosmisuper.DatosMiSuper)
  const prueba = useSelector(state => state.Datosmisuper)

  // const handleRedirect = () => {
  //   const encodeidentifier = btoa(DatosMiSuper.run+DatosMiSuper.dv);
  //   window.open(`https://reclamos-apb.superdesalud.gob.cl/validacion?state=${encodeidentifier}`, '_self');
  // }

  const handleRedirectFuria = () => {
    window.open(
      `${process.env.GATSBY_RECLAMOS}?run_atu=${DatosMiSuper.run}&dv_atu=${DatosMiSuper.dv}&nombres_atu=${DatosMiSuper.nombre}&apellido_p_atu=${DatosMiSuper.apellido_p}&apellido_m_atu=${DatosMiSuper.apellido_m}`,
      "_self"
    )
  }
  
  const handleRedirectAPB = () => {
    const run = DatosMiSuper.run.toString()
    var rut = `${run.substring(0, run.length-6)}.${run.substring(run.length-6, run.length-3)}.${run.substring(run.length-3, run.length)}-${DatosMiSuper.dv}`

    window.open(
      `https://reclamosdesa-apb.superdesalud.gob.cl/validacion2/?code=${btoa(rut)}`,
      "_self"
    )
  }
  
  return (
    <>
      <div className="contenedor ">
        <div className="dashboard-option-ss">
          <div className="option-principal-ss option-certificados">
            <div className="d-flex" onClick={() => handleRedirectFuria()}>
              <div>
                <FaEdit
                  style={{
                    fontSize: 24,
                    fontWeight: "normal",
                    color: "#0073ba",
                    marginTop: 15,
                  }}
                />
              </div>

              <div className="ml-3">
                <p
                  className="py-0"
                  style={{
                    color: "#444444",
                    fontWeight: "bold",
                    display: "block",
                  }}
                >
                  Ingreso de reclamo
                </p>
                <small style={{ display: "block" }}>
                  Ingresa aquí si deseas:
                </small>

                <ul className="pl-3">
                  <li><small>Reclamar contra una Isapre</small></li>
                  <li><small>Reclamar contra el Fondo Nacional de Salud (FONASA)</small></li>
                  <li><small>Reclamar contra un establecimiento de salud (Clínica,
                    Hospital, Consultorio, CESFAM, etc.){" "}</small>
                    
                  </li>
                  <li>
                    <small>Solicitar una mediación por negligencia médica en un
                    establecimiento privado de salud.</small>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>


        {/* <div className="dashboard-option-ss">
          <div className="option-principal-ss option-certificados">
            <div className="d-flex" onClick={() => handleRedirect()}>
              <div>
                <FaEdit
                  style={{
                    fontSize: 24,
                    fontWeight: "normal",
                    color: "#0073ba",
                    marginTop: 15,
                  }}
                />
              </div>

              <div className="ml-3">
                <p
                  className="py-0"
                  style={{
                    color: "#444444",
                    fontWeight: "bold",
                    display: "block",
                  }}
                >
                  Ingreso de Reclamo por el alza de precio
                </p>
                <small style={{ display: "block" }}>
                Ingresa aquí si deseas reclamar por el alza de tu plan de salud.

                </small>

              </div>
            </div>
          </div>
        </div> */}


        

        <div className="dashboard-option-ss">
          <div className="option-principal-ss option-reclamos">
            <div
              className="d-flex"
              onClick={() => navigate("/inicio/reclamos/antecedentes/")}
            >
              <div>
                <FaEdit
                  style={{
                    fontSize: 24,
                    fontWeight: "normal",
                    color: "#00BB3A",
                    marginTop: 15,
                  }}
                />
              </div>

              <div className="ml-3">
                <p
                  className="py-0"
                  style={{
                    color: "#444444",
                    fontWeight: "bold",
                    display: "block",
                  }}
                >
                  Ingreso de antecedentes adicionales
                </p>
                <small className="d-block">
                  Ingresa aquí si deseas adjuntar antecedentes adicionales o
                  hacer observaciones a un reclamo que se encuentra en
                  tramitación.
                </small>
              </div>
            </div>
          </div>
        </div>

        <div className="dashboard-option-ss">
          <div className="option-principal-ss option-excedentes">
            <div
              className="d-flex"
              onClick={() => navigate("/inicio/reclamos/recurso/")}
            >
              <div>
                <FaEdit
                  style={{
                    fontSize: 24,
                    fontWeight: "normal",
                    color: "#DC3A35",
                    marginTop: 15,
                  }}
                />
              </div>

              <div className="ml-3">
                <p
                  className="py-0"
                  style={{
                    color: "#444444",
                    fontWeight: "bold",
                    display: "block",
                  }}
                >
                  Presentación de recurso
                </p>
                <small className="d-block">
                Ingresa aquí si deseas modificar o dejar sin efecto, en los plazos establecidos, la resolución emitida por una de las Intendencias en virtud de un reclamo presentado. 
                </small>
              </div>
            </div>
          </div>
        </div>
        

        {/* <div className="dashboard-option-ss">
          <div className="option-principal-ss option-cotizaciones">
            <div
              className="d-flex"
              onClick={handleRedirectAPB}
            >
              <div>
                <FaEdit
                  style={{
                    fontSize: 24,
                    fontWeight: "normal",
                    color: "#F4B41C",
                    marginTop: 15,
                  }}
                />
              </div>

              <div className="ml-3">
                <p
                  className="py-0"
                  style={{
                    color: "#444444",
                    fontWeight: "bold",
                    display: "block",
                  }}
                >
                  Ingreso de reclamo por alza de precio base
                </p>
                <small className="d-block">
                  Ingresa aquí si deseas reclamar por la comunicación de alza de precio base.
                </small>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return state
}

export default connect(mapStateToProps)(ReclamosIndex)
