import React from "react"
import "../../components/SeguimientoComponents/seguimiento.css"
import LayoutProvider from "../../components/Layout"
import ReclamosIndex from '../../components/ReclamosComponents/index';

const ReclamosPage = ({ location }) => {



  return (
    <LayoutProvider title="Ingreso Reclamo" location={location}>
        
      <ReclamosIndex location={location}/> 
 
    </LayoutProvider>
  )
}

export default ReclamosPage
